<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <div id="app">
    <div id="app-wrapper">
    <app-header></app-header>
    <router-view></router-view>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import Navbar from '@/components/Navbar'
  import Footer from '@/components/Footer'

  export default {
    components: {
      'app-header': Navbar,
      'app-footer': Footer
    }
  }
</script>

<style lang="scss">
  #app-wrapper{
    min-height: 100vh;
  }
</style>
