<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" class="amdx-navbar">
        <b-navbar-brand to="/">
          <b-img class="navbar-logo" src="https://www.archimedes-exhibitions.de/site/uploads/2022/12/logo-new.svg"/>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
        <b-collapse id="nav-text-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/power" v-if="enablePowervisor">
              <b-icon-power></b-icon-power> {{ $t("ems.nav.power") }}
            </b-nav-item>
            <b-nav-item to="/timelines" v-if="enableTimeline">
              <b-icon-clock></b-icon-clock> {{ $t("ems.nav.timeline") }}
            </b-nav-item>
            <plugin-nav-element
              v-for="(plugin, i) in emsPlugins"
              :key="i"
              :plugin="plugin"
            ></plugin-nav-element>
            <b-nav-item
              v-for="(link, i) in externalLinks" :key="i"
              :href="link.url"
              target="_blank"
            ><b-icon :icon="link.icon"></b-icon> {{ $t(link.i18nId) }}
            </b-nav-item>
            <b-nav-item to="/cpacker" v-if="enableCPacker">
              <b-icon-file-zip-fill></b-icon-file-zip-fill> CPacker
              <!-- {{ $t("ems.nav.clients") }} -->
            </b-nav-item>
            <b-nav-item to="/clients" v-if="enableClients">
              <b-icon-Terminal></b-icon-Terminal> {{ $t("ems.nav.clients") }}
            </b-nav-item>
            <b-nav-item-dropdown v-if="enableDocs || enableDrivers">
              <template slot="button-content">
                <b-icon-list></b-icon-list> {{ $t('ems.nav.more') }}
              </template>
              <b-dropdown-item class="my-2" to="/drivers" v-if="enableDrivers">
                <b-icon-box-seam class="p-0"></b-icon-box-seam>
                {{ $t('ems.nav.drivers') }}
              </b-dropdown-item>
              <b-dropdown-item class="my-2" v-if="enableDocs" to="/docs">
                <b-icon-question-circle class="mr-2"></b-icon-question-circle>
                {{ $t('ems.nav.docs') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <template v-slot:button-content v-if="userInfo">
                <b-icon-person-circle></b-icon-person-circle> {{ userInfo.preferred_username }}
              </template>
              <b-dropdown-item class="my-2" to="/profile">
                <b-icon-person-lines-fill class="mr-2"></b-icon-person-lines-fill>
                {{ $t('ems.profile.profile') }}
              </b-dropdown-item>
              <b-dropdown-item class="my-2" @click="logout">
                <b-icon-arrow-bar-right class="mr-2"></b-icon-arrow-bar-right>
                {{ $t('ems.profile.signOut') }}
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group :header="$t('ems.profile.uiLanguage')">
                <b-dropdown-item class="my-2" v-for="(locale, i) in locales" :key="i"
                                 @click="changeLocale(locale)"
                                 :disabled="locale === currentlocale">
                  {{ locale.toUpperCase() }}
                </b-dropdown-item>
              </b-dropdown-group>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
</template>

<script>
  import Vue from 'vue'
  import { PluginNavElement } from 'emslib'

  export default {
    name: 'Navbar',
    components: {
      PluginNavElement
    },
    computed: {
      externalLinks () {
        if (Vue.prototype.$EXTERNAL_LINKS) {
          return Vue.prototype.$EXTERNAL_LINKS
        }
        return []
      },
      enablePowervisor () {
        return Vue.prototype.$ENABLE_POWERVISOR
      },
      enableTimeline () {
        return Vue.prototype.$ENABLE_TIMELINE
      },
      enableClients () {
        return Vue.prototype.$ENABLE_CLIENTS
      },
      enableDocs () {
        return Vue.prototype.$ENABLE_DOCS
      },
      enableDrivers () {
        return Vue.prototype.$ENABLE_DRIVERS
      },
      emsPlugins () {
        return Vue.emsPlugins
      },
      enableCPacker () {
        return Vue.prototype.$ENABLE_CPACKER
      },
      locales () {
        return this.$i18n.availableLocales
      },
      currentlocale () {
        return this.$root.$i18n.locale
      }
    },
    data () {
      return {
        userInfo: null
      }
    },
    methods: {
      loadUserInfo () {
        if (this.$keycloakmanager.isConfigured) {
          this.$keycloakmanager.loadUserInfo()
            .then(res => {
              this.userInfo = res
            }).catch(err => {
              console.log(err)
            })
        }
      },
      logout () {
        if (this.$keycloakmanager.isConfigured) {
          this.$keycloakmanager.logout()
        }
      },
      changeLocale (locale) {
        this.$root.$i18n.locale = locale
        localStorage['ems-locale'] = locale
      }
    },
    mounted () {
      this.loadUserInfo()
    }
  }
</script>

<style lang="scss" scoped>
  .navbar{
    border-bottom: 3px solid #ff5700;
  }
  .navbar-logo {
      height: 36px;
  }
  .main-nav{
    .nav-link {
      color: #fff!important;
      .b-icon {
        margin-right: 15px;
      }
    }
    li {
      margin-left: 15px;
    }
    .nav-link.active  {
      color: #ff5700!important;
    }
  }
</style>
