<template>
  <footer color="stylish-color-dark" class="page-footer font-small pt-4 mt-4">
    <b-container class="text-left">
      <b-row>
        <b-col md="8">
          <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">{{ $t('ems.footer.support') }}</h5>
          <ul class="list-unstyled">
            <li class="mb-4" style="white-space: pre-wrap">{{ $t('ems.footer.supportHint') }} <b-link :href="supportMail">{{ $t('ems.footer.email') }}</b-link></li>
            <li><b-link :href="supportMail"><b-img height="60" src="https://www.archimedes-exhibitions.de/site/uploads/2022/12/logo-new.svg"/></b-link></li>
          </ul>
        </b-col>
        <b-col md="2">
          <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">{{ $t('ems.footer.ems') }}</h5>
          <ul class="list-unstyled">
            <li><b-link to="/">{{ $t('ems.footer.map') }}</b-link></li>
            <li><b-link to="/power" v-if="enablePowervisor">{{ $t('ems.nav.power') }}</b-link></li>
            <li><b-link to="/timeline" v-if="enableTimeline">{{ $t('ems.nav.timeline') }}</b-link></li>
            <li><b-link to="/cms" v-if="enableClients">{{ $t('ems.nav.cms') }}</b-link></li>
            <li><b-link to="/clients" v-if="enableClients">{{ $t('ems.nav.clients') }}</b-link></li>
            <li><b-link to="/docs" v-if="enableDocs">{{ $t('ems.nav.docs') }}</b-link></li>
            <li><b-link to="/drivers" v-if="enableDrivers">{{ $t('ems.nav.drivers') }}</b-link></li>
          </ul>
        </b-col>
       <b-col md="2" v-if="enableDocs">
         <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">Docs</h5>
         <ul class="list-unstyled">
           <li><b-link :href="getPath([docsPath, currentlocale, '/latest/power.html'])" target="_blank">{{ $t('ems.nav.power') }}</b-link></li>
           <li><b-link :href="getPath([docsPath, currentlocale, '/latest/schedule.html'])" target="_blank">{{ $t('ems.nav.timeline') }}</b-link></li>
           <li><b-link :href="getPath([docsPath, currentlocale, '/latest/clients.html'])" target="_blank">{{ $t('ems.nav.clients') }}</b-link></li>
           <li><b-link :href="getPath([docsPath, currentlocale, '/latest/support.html'])" target="_blank">{{ $t('ems.footer.serviceSupport') }}</b-link></li>
         </ul>
       </b-col>
      </b-row>
    </b-container>
    <div class="text-center">
      <ul class="list-unstyled list-inline">
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-fb mx-1"><i class="fab fa-facebook"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-tw mx-1"><i class="fab fa-twitter"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-gplus mx-1"><i class="fab fa-google-plus"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-li mx-1"><i class="fab fa-linkedin-in"> </i></a></li>
        <li class="list-inline-item"><a class="btn-floating btn-sm btn-dribbble mx-1"><i class="fab fa-dribbble"> </i></a></li>
      </ul>
    </div>
    <div class="footer-copyright text-center py-3">
      <b-container fluid>
        &copy; 2023 Copyright: <b-link href="https://www.archimedes-exhibitions.de"> Archimedes-Exhibitions GmbH </b-link> | v{{ $VUE_APP_VERSION }}
      </b-container>
    </div>
  </footer>
</template>

<script>
  import path from 'path'
  import Vue from 'vue'

  export default {
    name: 'Footer',
    data () {
      return {
      }
    },
    computed: {
      supportMail () {
        return 'mailto:' + Vue.prototype.$SUPPORT_EMAIL
      },
      currentlocale () {
        return this.$root.$i18n.locale
      },
      baseURL () {
        return Vue.prototype.$EMSWEB_BASE_PATH
      },
      enablePowervisor () {
        return Vue.prototype.$ENABLE_POWERVISOR
      },
      enableTimeline () {
        return Vue.prototype.$ENABLE_TIMELINE
      },
      enableClients () {
        return Vue.prototype.$ENABLE_CLIENTS
      },
      enableDocs () {
        return Vue.prototype.$ENABLE_DOCS
      },
      enableDrivers () {
        return Vue.prototype.$ENABLE_DRIVERS
      },
      docsPath () {
        return Vue.prototype.$EMSWEB_DOCS_PATH
      }
    },
    methods: {
      getPath (components) {
        return path.join(...components)
      }
    }
  }
</script>
<style lang="scss" scoped>
  footer {
    background-color: #f2f2f2;
    border-top: 3px solid #dee2e6;
    text-align: center;
  }

</style>
