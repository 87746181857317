/*
 * Copyright (C) 2020. Archimedes Exhibitions GmbH,
 * Saarbrücker Str. 24, Berlin, Germany
 *
 * This file contains proprietary source code and confidential
 * information. Its contents may not be disclosed or distributed to
 * third parties unless prior specific permission by Archimedes
 * Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
 * to copies made in any form and using any medium. It applies to
 * partial as well as complete copies.
 */

import Keycloak from 'keycloak-js'

export default class KeyCloakManager {
  constructor (config = null) {
    this._keyCloak = null
    this._config = config
    this._loginCallbacks = []
    this._userInfo = null

    if (config) {
      this._keyCloak = new Keycloak(config)

      this._keyCloak.onTokenExpired = () => {
        this._keyCloak.updateToken(1).then(() => {
          // console.debug('successfully get a new token', this._keyCloak.token)
        }).catch(() => {
          console.debug('Failed to refresh the token, or the session has expired')
          this._keyCloak.logout()
        })
      }

      this._keyCloak.onAuthLogout = () => {
        console.info('Logged out.')
      }

      this._keyCloak.onAuthSuccess = () => {
        console.info('Logged in.')
        for (let c of this._loginCallbacks) {
          c()
        }
      }
    }
  }

  get keyCloak () {
    return this._keyCloak
  }

  get isConfigured () {
    return !!this._keyCloak
  }

  get token () {
    return this._keyCloak.token
  }

  // fetchUser () {
  //   return new Promise((resolve, reject) => {
  //     this._keyCloak.loadUserInfo().then(res => {
  //       resolve(res)
  //     }).catch(err => {
  //       console.error('error fetching userinfo: ' + err)
  //       reject(err)
  //     })
  //   })
  // }

  init () {
    if (!this._config) {
      for (let c of this._loginCallbacks) {
        c()
      }
      return
    }
    this._keyCloak.init({ onLoad: this._config.onLoad })
      .then(authenticated => {
        console.info(
          authenticated ? 'Authenticated' : 'not authenticated'
        )
      }).catch((err) => {
        console.error(err)
      })
  }

  loadUserInfo () {
    return this._keyCloak.loadUserInfo()
  }

  loadUserProfile () {
    return this._keyCloak.loadUserProfile()
  }

  logout () {
    this._keyCloak.logout()
  }

  updateToken (minValidity = null) {
    return this._keyCloak.updateToken(minValidity)
  }

  isTokenExpired (minValidity = null) {
    return this._keyCloak.isTokenExpired(minValidity)
  }

  clearToken () {
    return this._keyCloak.clearToken()
  }

  addLoginCallback (c) {
    this._loginCallbacks.push(c)
  }

  removeLoginCallback (c) {
    let index = this._loginCallbacks.indexOf(c)
    if (index > -1) {
      this._loginCallbacks.splice(index, 1)
    }
  }

  getTokenHeader () {
    let header = {
      'Content-Type': 'application/json'
    }
    if (this._keyCloak) {
      header['Authorization'] = 'Bearer ' + this._keyCloak.token
    }
    return header
  }
}
